<script setup>
    import { computed } from 'vue';
    import Fraction from '@/fraction.js';

    const props = defineProps(['stepID', 'rulersMax', 'windowWidth', 'windowHeight', 'stack', 'mountingHeight', 'packageType', 'rulerAdjustment']);

    const totalDraperyWidth = computed(() => {
        if (props.rulersMax && props.windowWidth && props.stack) {
            const draperyWidth = props.windowWidth.value + props.rulersMax.value * 2;
            return `${Fraction.format.to(draperyWidth)} In`;
        }
        return null;
    });

    const totalMountingHeight = computed(() => {
        if (props.rulersMax && props.windowHeight) {
            const draperyHeight = props.windowHeight.value + props.mountingHeight.value;
            return `${Fraction.format.to(draperyHeight)} In`;
        }
        return null;
    });
</script>

<template>
    <div v-if="stepID === 'stackWidth'" class="total__amount is--configurating total__shade-height">{{ totalDraperyWidth }}</div>
    <div v-else-if="stepID === 'mountingHeight'" class="total__amount is--configurating total__shade-height">{{ totalMountingHeight }}</div>
</template>
import { ref, watch, onBeforeMount } from 'vue';
import { debounce } from 'throttle-debounce';
import { useBackend } from '@/composables/Backend';

export function usePriceCheck(data) {
    const backend = useBackend(data);

    const isCalculating = ref(false);
    const price = ref(-1);
    const salePrice = ref(-1);
    const inStock = ref(false);
    const error = ref('');

    const checkPrice = debounce(800, () => {
        error.value = '';
        isCalculating.value = true;

        backend.calculatePrice(data)
            .then((result) => {
                if (result == false || result.error) {
                    if (result.error) {
                        error.value = result.error;
                    }
                    else {
                        error.value = 'An error occurred getting the item price from the API.';
                    }
                    console.error(`price check error: ${error.value}`);
                }
                else {
                    price.value = result.price;
                    salePrice.value = result.salePrice;
                    inStock.value = result.inStock;
                    
                    isCalculating.value = false;
                }
            });
    });

    onBeforeMount(() => {
        Object.entries(data.steps).forEach((kv) => {
            const step = kv[1];

            // add a watcher to each step's selected property which will update the price data from the backend
            watch(step.selected, () => {
                checkPrice();
            });

            // if a step has a rulerMax property then watch it for changes too
            if (step.rulersMax) {
                watch(step.rulersMax, () => {
                    checkPrice();
                });
            }
        });

        // do an initial price check
        checkPrice();
    });
    
    return {
        isCalculating,
        price,
        salePrice,
        inStock,
        error
    }
}